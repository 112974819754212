
import { defineComponent } from "vue";

import { useResourceDelete } from "@tager/admin-services";
import {
  useDataTable,
  DataTable,
  CountButton,
  BaseButton,
  EditIcon,
  DeleteIcon,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getCompanyFormUrl, getPaymentListUrl } from "@/utils/paths";
import { COLUMN_DEFS } from "@/modules/companies/CompanyList/CompanyList.helpers";
import { deleteCompany, getCompanyList } from "@/modules/companies/requests";
import { CompanyType } from "@/modules/companies/typings";

export default defineComponent({
  name: "CompanyList",
  components: {
    Page,
    DataTable,
    CountButton,
    BaseButton,
    EditIcon,
    DeleteIcon,
  },
  setup() {
    const {
      isLoading: isCompanyListDataLoading,
      rowData: companyListData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      fetchEntityList: fetchCompanyList,
    } = useDataTable<CompanyType>({
      fetchEntityList: (params) =>
        getCompanyList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список компаний",
    });

    const { handleResourceDelete: handleCompanyDelete, isDeleting } =
      useResourceDelete({
        deleteResource: deleteCompany,
        resourceName: "Компания",
        onSuccess: fetchCompanyList,
      });

    function isBusy(companyId: number): boolean {
      return isDeleting(companyId) || isCompanyListDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: companyListData,
      isRowDataLoading: isCompanyListDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      getCompanyFormUrl,

      isBusy,
      handleCompanyDelete,
      getPaymentListUrl,
    };
  },
});
