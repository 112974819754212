import {RouteRecordRaw} from "vue-router";

import {getLinks} from "@/constants/links";

import {ROUTE_PATHS} from "@/constants/paths";

import CompanyList from './CompanyList'
import CompanyForm from './CompanyForm'

export const COMPANY_LIST_ROUTE: RouteRecordRaw = {
    path: ROUTE_PATHS.COMPANY_LIST,
    component: CompanyList,
    name: 'Компании',
    meta: {
        getBreadcrumbs: () => [getLinks().HOME, getLinks().COMPANY_LIST],
    },
};

export const COMPANY_FORM_ROUTE: RouteRecordRaw = {
    path: ROUTE_PATHS.COMPANY_FORM,
    component: CompanyForm,
    name: 'Форма компании',
    meta: {
        getBreadcrumbs: (route) => [
            getLinks().HOME,
            getLinks().COMPANY_LIST,
            {
                url: route.path,
                text: 'Форма компании',
            },
        ],
    },
};
