import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87bfa4ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "service-parameters"
}
const _hoisted_2 = {
  key: 1,
  class: "service-parameters"
}
const _hoisted_3 = {
  key: 2,
  class: "service-parameters"
}
const _hoisted_4 = {
  key: 3,
  class: "service-parameters"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldCheckbox = _resolveComponent("FormFieldCheckbox")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isContentLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": _ctx.isCreation,
        "can-create-another": _ctx.isCreation,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "is-creation", "can-create-another", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[23] || (_cache[23] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_FormField, {
          value: _ctx.values.name,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
          name: "name",
          error: _ctx.errors.name,
          label: "Название"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.systemName,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.systemName) = $event)),
          name: "name",
          error: _ctx.errors.systemName,
          label: "Системное название"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormFieldCheckbox, {
          checked: _ctx.values.webpayActive,
          "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.webpayActive) = $event)),
          class: "service-parameters-checkbox",
          name: "webpayActive",
          error: _ctx.errors.webpayActive,
          label: "WEBPAY"
        }, null, 8, ["checked", "error"]),
        (_ctx.values.webpayActive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_FormFieldCheckbox, {
                checked: _ctx.values.webpaySandbox,
                "onUpdate:checked": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.webpaySandbox) = $event)),
                name: "webpaySandbox",
                error: _ctx.errors.webpaySandbox,
                label: "WEBPAY - Тестовый режим"
              }, null, 8, ["checked", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.webpayStoreId,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.webpayStoreId) = $event)),
                name: "webpayStoreId",
                error: _ctx.errors.webpayStoreId,
                label: "WEBPAY - ID Магазина"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.webpaySecretKey,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.webpaySecretKey) = $event)),
                name: "webpaySecretKey",
                error: _ctx.errors.webpaySecretKey,
                label: "WEBPAY - Секретный Ключ"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.webpayCabinetLogin,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.values.webpayCabinetLogin) = $event)),
                name: "webpayCabinetLogin",
                error: _ctx.errors.webpayCabinetLogin,
                label: "WEBPAY - Логин"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.webpayCabinetPassword,
                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.values.webpayCabinetPassword) = $event)),
                name: "webpayCabinetPassword",
                error: _ctx.errors.webpayCabinetPassword,
                label: "WEBPAY - Пароль"
              }, null, 8, ["value", "error"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_FormFieldCheckbox, {
          checked: _ctx.values.webpayEripActive,
          "onUpdate:checked": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.values.webpayEripActive) = $event)),
          class: "service-parameters-checkbox",
          name: "webpayEripActive",
          error: _ctx.errors.webpayEripActive,
          label: "WEBPAY - ЕРИП"
        }, null, 8, ["checked", "error"]),
        (_ctx.values.webpayEripActive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_FormFieldCheckbox, {
                checked: _ctx.values.webpayEripSandbox,
                "onUpdate:checked": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.values.webpayEripSandbox) = $event)),
                name: "webpayEripSandbox",
                error: _ctx.errors.webpayEripSandbox,
                label: "WEBPAY ЕРИП - Тестовый режим"
              }, null, 8, ["checked", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.webpayEripStoreId,
                "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.values.webpayEripStoreId) = $event)),
                name: "webpayEripStoreId",
                error: _ctx.errors.webpayEripStoreId,
                label: "WEBPAY ЕРИП - ID Магазина"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.webpayEripSecretKey,
                "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.values.webpayEripSecretKey) = $event)),
                name: "webpayEripSecretKey",
                error: _ctx.errors.webpayEripSecretKey,
                label: "WEBPAY ЕРИП - Секретный Ключ"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.webpayEripCabinetLogin,
                "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.values.webpayEripCabinetLogin) = $event)),
                name: "webpayEripCabinetLogin",
                error: _ctx.errors.webpayEripCabinetLogin,
                label: "WEBPAY ЕРИП - Логин"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.webpayEripCabinetPassword,
                "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.values.webpayEripCabinetPassword) = $event)),
                name: "webpayEripCabinetPassword",
                error: _ctx.errors.webpayEripCabinetPassword,
                label: "WEBPAY ЕРИП - Пароль"
              }, null, 8, ["value", "error"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_FormFieldCheckbox, {
          checked: _ctx.values.oplatiActive,
          "onUpdate:checked": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.values.oplatiActive) = $event)),
          class: "service-parameters-checkbox",
          name: "oplatiActive",
          error: _ctx.errors.oplatiActive,
          label: "OPLATI"
        }, null, 8, ["checked", "error"]),
        (_ctx.values.oplatiActive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_FormFieldCheckbox, {
                checked: _ctx.values.oplatiSandbox,
                "onUpdate:checked": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.values.oplatiSandbox) = $event)),
                name: "oplatiSandbox",
                error: _ctx.errors.oplatiSandbox,
                label: "OPLATI - Тестовый режим"
              }, null, 8, ["checked", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.oplatiRegnum,
                "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.values.oplatiRegnum) = $event)),
                name: "oplatiRegnum",
                error: _ctx.errors.oplatiRegnum,
                label: "OPLATI - Regnum"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.oplatiPassword,
                "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.values.oplatiPassword) = $event)),
                name: "oplatiPassword",
                error: _ctx.errors.oplatiPassword,
                label: "OPLATI - Пароль"
              }, null, 8, ["value", "error"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_FormFieldCheckbox, {
          checked: _ctx.values.assistActive,
          "onUpdate:checked": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.values.assistActive) = $event)),
          class: "service-parameters-checkbox",
          name: "assistActive",
          error: _ctx.errors.assistActive,
          label: "ASSIST"
        }, null, 8, ["checked", "error"]),
        (_ctx.values.assistActive)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_FormFieldCheckbox, {
                checked: _ctx.values.assistSandbox,
                "onUpdate:checked": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.values.assistSandbox) = $event)),
                name: "assistSandbox",
                error: _ctx.errors.assistSandbox,
                label: "ASSIST - Тестовый режим"
              }, null, 8, ["checked", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.assistMerchantId,
                "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.values.assistMerchantId) = $event)),
                name: "assistMerchantId",
                error: _ctx.errors.assistMerchantId,
                label: "ASSIST - Merchant ID"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.assistUsername,
                "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.values.assistUsername) = $event)),
                name: "assistUsername",
                error: _ctx.errors.assistUsername,
                label: "ASSIST - Логин"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.assistPassword,
                "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.values.assistPassword) = $event)),
                name: "assistPassword",
                error: _ctx.errors.assistPassword,
                label: "ASSIST - Пароль"
              }, null, 8, ["value", "error"])
            ]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}