
import {computed, defineComponent, onMounted} from 'vue';
import {useRoute} from "vue-router";
import qrcode from 'qrcode-generator';

import {Nullable, useResource} from '@tager/admin-services';
import {FullPaymentType} from '../typings';
import {getPayment} from '../requests';
import {getPaymentListUrl} from '@/utils/paths';
import {formatDateTime, FieldValue} from '@tager/admin-ui';
import {Page} from "@tager/admin-layout";

export default defineComponent({
  name: 'PaymentView',
  components: {Page, FieldValue},
  setup() {
    const route = useRoute();

    const paymentId = computed<string>(
        () => route.params.paymentId as string
    );

    const [
      fetchPaymentForm,
      {data: payment, loading: isPaymentLoading},
    ] = useResource<Nullable<FullPaymentType>>({
      fetchResource: () => getPayment(paymentId.value),
      initialValue: null,
      resourceName: 'Платёж',
    });

    const loadQr = () => {
      if (payment.value?.paymentOplati) {
        const qr = qrcode(4, 'L');
        qr.addData(payment.value?.paymentOplati?.dynamicQr);
        qr.make();
        const placeholder = document.getElementById('dynamic-qr');
        if (placeholder) {
          placeholder.innerHTML = qr.createImgTag();
          console.log('here');
        }
      }
    };

    onMounted(() => {
      fetchPaymentForm().then(() => loadQr());
    });

    const isContentLoading = computed<boolean>(() => isPaymentLoading.value);

    const pageTitle = computed<string>(() => {
      return payment.value?.id ? `Платеж № ${payment.value?.id}` : 'Платёж';
    });

    return {
      backButtonUrl: getPaymentListUrl(),
      payment,
      isContentLoading,
      formatDateTime,
      pageTitle,
    };
  },
});
