import { ColumnDefinition } from "@tager/admin-ui";

import { getCompanyFormUrl } from "@/utils/paths";
import { CompanyType } from "@/modules/companies/typings";

export const COLUMN_DEFS: Array<ColumnDefinition<CompanyType>> = [
  {
    id: 1,
    name: "Название",
    field: "name",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getCompanyFormUrl({ companyId: row.id }),
      },
    }),
  },
  {
    id: 2,
    name: "Системное название",
    field: "systemName",
  },
  {
    id: 3,
    name: "Оплати",
    field: "oplati",
  },
  {
    id: 4,
    name: "WebPay",
    field: "webpay",
  },
  {
    id: 5,
    name: "WebPay (ЕРИП)",
    field: "webpayerip",
  },
  {
    id: 6,
    name: "Assist",
    field: "assist",
  },
  {
    id: 7,
    name: "Платежи",
    field: "payments",
    headStyle: { width: "200px" },
  },
  {
    id: 8,
    name: "Действия",
    field: "actions",
    style: { width: "105px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "105px", textAlign: "center" },
  },
];
