import { ROUTE_PATHS } from '@/constants/paths';
import { compile } from 'path-to-regexp';

export function getCompanyListUrl() {
  return ROUTE_PATHS.COMPANY_LIST;
}

export function getCompanyFormUrl(params: { companyId: string | number }) {
  return compile(ROUTE_PATHS.COMPANY_FORM)(params);
}

export function getPaymentListUrl() {
  return ROUTE_PATHS.PAYMENT_LIST;
}

export function getPaymentViewUrl(params: { paymentId: string | number }) {
  return compile(ROUTE_PATHS.PAYMENT_VIEW)(params);
}
