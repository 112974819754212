import {LinkType} from '@tager/admin-ui';
import {getAdminListUrl, getRoleListUrl} from '@tager/admin-administrators';
import {getSettingItemListUrl} from '@tager/admin-settings';
import {getCompanyListUrl, getPaymentListUrl} from '@/utils/paths';

type LinkKey =
    | 'HOME'
    | 'COMPANY_LIST'
    | 'PAYMENT_LIST'
    | 'ADMIN_ROLES'
    | 'ADMIN_ADMINS'
    | 'SETTINGS_LIST';

export const getLinks = (): Readonly<Record<LinkKey, LinkType>> => ({
    HOME: {
        url: '/',
        text: 'Главная',
    },
    COMPANY_LIST: {
        url: getCompanyListUrl(),
        text: 'Компании',
    },
    PAYMENT_LIST: {
        url: getPaymentListUrl(),
        text: 'Платежи',
    },
    ADMIN_ROLES: {
        url: getRoleListUrl(),
        text: 'Роли',
    },
    ADMIN_ADMINS: {
        url: getAdminListUrl(),
        text: 'Администраторы',
    },
    SETTINGS_LIST: {
        url: getSettingItemListUrl(),
        text: 'Настройки',
    },
});
