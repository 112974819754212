import {ColumnDefinition} from "@tager/admin-ui";
import {PaymentType} from "@/modules/payments/typings";
import {getCompanyFormUrl} from "@/utils/paths";

export const COLUMN_DEFS: Array<ColumnDefinition<PaymentType>> = [
    {
        id: 1,
        name: 'ID',
        field: 'id',
        style: {width: '52px', textAlign: 'center', whiteSpace: 'nowrap'},
        headStyle: {width: '52px', textAlign: 'center'},
    },
    {
        id: 2,
        name: 'UUID',
        field: 'uuid',
        style: {width: '333px'},
    },
    {
        id: 3,
        name: 'Номер',
        field: 'number',
    },
    {
        id: 4,
        name: 'Компания',
        field: 'company',
        type: 'link',
        format: ({row}) =>
            row.company
                ? {
                    text: row.company.name,
                    url: getCompanyFormUrl({companyId: row.company.id}),
                }
                : null,
    },
    {
        id: 5,
        name: 'Способ оплаты',
        field: 'provider',
        style: {width: '132px'},
    },
    {
        id: 6,
        name: 'Сумма',
        field: 'total',
        style: {width: '115px'},
        format: ({row}) => row.total + ' BYN',
    },
    {
        id: 7,
        name: 'Статус',
        field: 'status',
        style: {width: '88px'},
    },
    {
        id: 8,
        name: 'Действия',
        field: 'actions',
        style: {width: '105px', textAlign: 'center', whiteSpace: 'nowrap'},
        headStyle: {width: '105px', textAlign: 'center'},
    },
];