
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from 'vue';

import {
  getPaymentList,
  getPaymentProviderLabelList,
  getPaymentStatusLabelList,
} from '../requests';

import {getCompanyList, CompanyType} from '@/modules/companies';

import {Label, PaymentType} from '../typings';

import {getCompanyFormUrl, getPaymentViewUrl} from '@/utils/paths';

import {
  ColumnDefinition,
  FilterTagType,
  getFilterParamAsNumberArray,
  getFilterParamAsString,
  getFilterParamAsStringArray,
  getFilterParams,
  OptionType,
  useDataTable,
  useSelectOptionsResource,
  BaseButton,
  CountButton,
  AdvancedSearch,
  EditIcon,
  FormField,
  DataTable,
  MenuIcon,
  FormFieldMultiSelect
} from '@tager/admin-ui';
import _ from 'lodash';
import {dateFormat} from '@/utils/common';
import {FETCH_STATUSES, notEmpty} from '@tager/admin-services';
import {Page} from "@tager/admin-layout";
import {useRoute, useRouter} from "vue-router";
import {COLUMN_DEFS} from './PaymentList.helpers';

export default defineComponent({
  name: 'PaymentList',
  components: {
    Page,
    BaseButton,
    CountButton,
    AdvancedSearch,
    EditIcon,
    FormField,
    DataTable,
    FormFieldMultiSelect,
    MenuIcon
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const {
      loading: isStatusListLoading,
      status: statusListRequestStatus,
      options: statusOptionList,
      fetchEntityList: fetchStatusList,
    } = useSelectOptionsResource<Label, OptionType>({
      fetchEntityList: getPaymentStatusLabelList,
      resourceName: 'Список статусов платежей',
      minQueryLength: 0,
      convertEntityToOption: (status) => {
        return {
          value: status.name,
          label: status.label,
        };
      },
    });

    const {
      loading: isProviderListLoading,
      status: providerListRequestStatus,
      options: providerOptionList,
      fetchEntityList: fetchProviderList,
    } = useSelectOptionsResource<Label, OptionType>({
      fetchEntityList: getPaymentProviderLabelList,
      resourceName: 'Список провайдеров платежей',
      minQueryLength: 0,
      convertEntityToOption: (provider) => {
        return {
          value: provider.name,
          label: provider.label,
        };
      },
    });

    const {
      loading: isCompanyListLoading,
      status: companyListRequestStatus,
      options: companyOptionList,
      fetchEntityList: fetchCompanyList,
    } = useSelectOptionsResource<CompanyType, OptionType<number>>({
      fetchEntityList: getCompanyList,
      resourceName: 'Список компаний',
      minQueryLength: 0,
      convertEntityToOption: (company) => {
        return {
          value: company.id,
          label: company.name,
        };
      },
    });

    onMounted(() => {
      fetchStatusList({query: ''});
      fetchProviderList({query: ''});
      fetchCompanyList({query: ''});
    });

    const initialStatusFilter = computed<Array<OptionType>>(() => {
      const queryValue = getFilterParamAsStringArray(
          route.query,
          'status'
      );

      return statusOptionList.value.filter((option) =>
          queryValue.some((selected) => option.value === selected)
      );
    });

    const statusFilter = ref<Array<OptionType>>(initialStatusFilter.value);

    watch(initialStatusFilter, () => {
      statusFilter.value = initialStatusFilter.value;
    });

    const initialProviderFilter = computed<Array<OptionType>>(() => {
      const queryValue = getFilterParamAsStringArray(
          route.query,
          'provider'
      );

      return providerOptionList.value.filter((option) =>
          queryValue.some((selected) => option.value === selected)
      );
    });

    const providerFilter = ref<Array<OptionType>>(initialProviderFilter.value);

    watch(initialProviderFilter, () => {
      providerFilter.value = initialProviderFilter.value;
    });

    const initialCompanyFilter = computed<Array<OptionType<number>>>(() => {
      const queryValue = getFilterParamAsNumberArray(
          route.query,
          'company'
      );

      return companyOptionList.value.filter((option) =>
          queryValue.some((selected) => option.value === selected)
      );
    });

    const companyFilter = ref<Array<OptionType<number>>>(
        initialCompanyFilter.value
    );

    watch(initialCompanyFilter, () => {
      companyFilter.value = initialCompanyFilter.value;
    });

    const initialFromDateFilter = computed<string>(
        () => getFilterParamAsString(route.query, 'fromDate') ?? ''
    );

    const fromDateFilter = ref<string>(initialFromDateFilter.value);

    watch(initialFromDateFilter, () => {
      fromDateFilter.value = initialFromDateFilter.value;
    });

    const initialToDateFilter = computed<string>(
        () => getFilterParamAsString(route.query, 'toDate') ?? ''
    );

    const toDateFilter = ref<string>(initialToDateFilter.value);

    watch(initialToDateFilter, () => {
      toDateFilter.value = initialToDateFilter.value;
    });

    const date = computed<FilterTagType | null>(() => {
      if (!fromDateFilter.value && !toDateFilter.value) {
        return null;
      }

      let label = '';

      if (fromDateFilter.value && toDateFilter.value) {
        label = `${dateFormat(fromDateFilter.value)} - ${dateFormat(
            toDateFilter.value
        )}`;
      }

      if (fromDateFilter.value && !toDateFilter.value) {
        label = `От ${dateFormat(fromDateFilter.value)}`;
      }

      if (!fromDateFilter.value && toDateFilter.value) {
        label = `До ${dateFormat(toDateFilter.value)}`;
      }

      return {
        value: '',
        label,
        name: 'fromDate',
        title: 'Дата',
      };
    });

    const filterParams = computed(() => {
      return getFilterParams({
        status: statusFilter.value.map((tag) => tag.value),
        provider: providerFilter.value.map((tag) => tag.value),
        company: companyFilter.value.map((tag) => tag.value),
        fromDate: fromDateFilter.value,
        toDate: toDateFilter.value,
      });
    });

    const {
      isLoading: isPaymentListDataLoading,
      rowData: paymentListData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      fetchEntityList: fetchPaymentList,
    } = useDataTable<PaymentType>({
      fetchEntityList: (params) =>
          getPaymentList({
            query: params.searchQuery,
            pageNumber: params.pageNumber,
            pageSize: params.pageSize,
            ...filterParams.value,
          }),
      initialValue: [],
      resourceName: 'Список платежей',
    });

    const isAllFilterDataLoaded = computed(() => {
      return [
        statusListRequestStatus.value,
        providerListRequestStatus.value,
        companyListRequestStatus.value,
      ].every((status) => status === FETCH_STATUSES.SUCCESS);
    });

    watch(filterParams, () => {
      if (!isAllFilterDataLoaded.value) return;

      const newQuery = {
        ...filterParams.value,
        query: route.query.query,
      };

      if (!_.isEqual(route.query, newQuery)) {
        router.replace({
          query: newQuery,
        });
        fetchPaymentList();
      }
    });

    function handleTagRemove(event: FilterTagType) {
      if (event.name === 'status') {
        statusFilter.value = statusFilter.value.filter(
            (tag) => String(tag.value) !== event.value
        );
      }
      if (event.name === 'provider') {
        providerFilter.value = providerFilter.value.filter(
            (tag) => String(tag.value) !== event.value
        );
      }
      if (event.name === 'company') {
        companyFilter.value = companyFilter.value.filter(
            (tag) => String(tag.value) !== event.value
        );
      }
      if (event.name === 'fromDate') {
        fromDateFilter.value = '';
        toDateFilter.value = '';
      }
    }

    const tags = computed<Array<FilterTagType>>(() =>
        [
          ...statusFilter.value.map((tag) => ({
            value: String(tag.value),
            label: tag.label,
            name: 'status',
            title: 'Статусы',
          })),
          ...providerFilter.value.map((tag) => ({
            value: String(tag.value),
            label: tag.label,
            name: 'provider',
            title: 'Провайдеры',
          })),
          ...companyFilter.value.map((tag) => ({
            value: String(tag.value),
            label: tag.label,
            name: 'company',
            title: 'Компании',
          })),
          date.value,
        ].filter(notEmpty)
    );

    function isBusy(): boolean {
      return (
          isPaymentListDataLoading.value ||
          isStatusListLoading.value ||
          isProviderListLoading.value ||
          isCompanyListLoading.value
      );
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: paymentListData,
      isRowDataLoading: isPaymentListDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      getPaymentViewUrl,
      isBusy,
      statusOptionList,
      statusFilter,
      fromDateFilter,
      toDateFilter,
      providerOptionList,
      providerFilter,
      companyOptionList,
      companyFilter,
      handleTagRemove,
      tags,
    };
  },
});
