import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35a699b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-allow"
}
const _hoisted_2 = {
  key: 1,
  class: "text-error"
}
const _hoisted_3 = {
  key: 2,
  class: "text-secondary"
}
const _hoisted_4 = { class: "status-field" }
const _hoisted_5 = {
  key: 0,
  class: "text-allow"
}
const _hoisted_6 = {
  key: 1,
  class: "text-error"
}
const _hoisted_7 = {
  key: 2,
  class: "text-secondary"
}
const _hoisted_8 = { class: "status-field" }
const _hoisted_9 = {
  key: 0,
  class: "text-allow"
}
const _hoisted_10 = {
  key: 1,
  class: "text-error"
}
const _hoisted_11 = {
  key: 2,
  class: "text-secondary"
}
const _hoisted_12 = {
  key: 0,
  class: "text-allow"
}
const _hoisted_13 = {
  key: 1,
  class: "text-error"
}
const _hoisted_14 = {
  key: 2,
  class: "text-secondary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountButton = _resolveComponent("CountButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_DeleteIcon = _resolveComponent("DeleteIcon")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: "Компании",
    "header-buttons": [
      {
        text: 'Добавить компанию',
        href: _ctx.getCompanyFormUrl({ companyId: 'create' }),
      },
    ]
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
        pageNumber: _ctx.pageNumber,
        pageCount: _ctx.pageCount,
        pageSize: _ctx.pageSize,
        disabled: _ctx.isRowDataLoading,
      },
        onChange: _ctx.handleChange
      }, {
        "cell(oplati)": _withCtx(({ row }) => [
          _createElementVNode("span", null, [
            (row.oplatiActive)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, "Включен"))
              : (_openBlock(), _createElementBlock("span", _hoisted_2, "Выключен")),
            (row.oplatiSandbox)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, " Test Mode "))
              : _createCommentVNode("", true)
          ])
        ]),
        "cell(webpayerip)": _withCtx(({ row }) => [
          _createElementVNode("span", _hoisted_4, [
            (row.webpayEripActive)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, "Включен"))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, "Выключен")),
            (row.webpayEripSandbox)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Test Mode "))
              : _createCommentVNode("", true)
          ])
        ]),
        "cell(webpay)": _withCtx(({ row }) => [
          _createElementVNode("span", _hoisted_8, [
            (row.webpayActive)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, "Включен"))
              : (_openBlock(), _createElementBlock("span", _hoisted_10, "Выключен")),
            (row.webpaySandbox)
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, " Test Mode "))
              : _createCommentVNode("", true)
          ])
        ]),
        "cell(assist)": _withCtx(({ row }) => [
          _createElementVNode("span", null, [
            (row.assistActive)
              ? (_openBlock(), _createElementBlock("span", _hoisted_12, "Включен"))
              : (_openBlock(), _createElementBlock("span", _hoisted_13, "Выключен")),
            (row.assistSandbox)
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, " Test Mode "))
              : _createCommentVNode("", true)
          ])
        ]),
        "cell(payments)": _withCtx(({ row }) => [
          _createVNode(_component_router_link, {
            to: {
            path: _ctx.getPaymentListUrl(),
            query: { 'filter[company]': row.id },
          }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CountButton, {
                variant: "outline-secondary",
                count: row.paymentsCount
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Платежи ")
                ]),
                _: 2
              }, 1032, ["count"])
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        "cell(actions)": _withCtx(({ row }) => [
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Edit",
            disabled: _ctx.isBusy(row.id),
            href: _ctx.getCompanyFormUrl({ companyId: row.id })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EditIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "href"]),
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Delete",
            disabled: _ctx.isBusy(row.id),
            onClick: ($event: any) => (_ctx.handleCompanyDelete(row.id))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DeleteIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "onChange"])
    ]),
    _: 1
  }, 8, ["header-buttons"]))
}