import {RouteRecordRaw} from "vue-router";

import {getLinks} from "@/constants/links";

import {ROUTE_PATHS} from "@/constants/paths";

import PaymentList from './PaymentList'
import PaymentView from './PaymentView'

export const PAYMENT_LIST_ROUTE: RouteRecordRaw = {
    path: ROUTE_PATHS.PAYMENT_LIST,
    component: PaymentList,
    name: 'Платежи',
    meta: {
        getBreadcrumbs: () => [getLinks().HOME, getLinks().PAYMENT_LIST],
    },
};

export const PAYMENT_VIEW_ROUTE: RouteRecordRaw = {
    path: ROUTE_PATHS.PAYMENT_VIEW,
    component: PaymentView,
    name: 'Просмотр платежа',
    meta: {
        getBreadcrumbs: (route, t) => [
            getLinks().HOME,
            getLinks().PAYMENT_LIST,
            {
                url: route.path,
                text: 'Просмотр платежа',
            },
        ],
    },
};
