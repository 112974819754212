import {request, ResponseBody} from '@tager/admin-services';

import {PaymentType, Label, FullPaymentType} from './typings'

export interface SuccessData {
    success: boolean;
}

export interface CountData {
    count: number;
}

export function getPaymentCount(): Promise<ResponseBody<CountData>> {
    return request.get({path: '/admin/payments/count'});
}

export function getPaymentList(params?: {
    query?: string;
    pageNumber?: number;
    pageSize?: number;
}): Promise<ResponseBody<Array<PaymentType>>> {
    return request.get({path: '/admin/payments', params});
}

export function getPayment(
    companyId: string | number
): Promise<ResponseBody<FullPaymentType>> {
    return request.get({path: `/admin/payments/${companyId}`});
}

export function getPaymentStatusLabelList(): Promise<ResponseBody<Array<Label>>> {
    return request.get({path: '/admin/payments/status-list'});
}

export function getPaymentProviderLabelList(): Promise<ResponseBody<Array<Label>>> {
    return request.get({path: '/admin/payments/provider-list'});
}
