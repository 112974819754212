
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import {
  FormFooter,
  TagerFormSubmitEvent,
  FormField,
  FormFieldCheckbox,
} from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getCompanyFormUrl, getCompanyListUrl } from "@/utils/paths";

import { FullCompanyType } from "../typings";
import { createCompany, getCompany, updateCompany } from "../requests";

import {
  convertCompanyToFormValues,
  convertFormValuesToCompanyCreationPayload,
  convertFormValuesToCompanyUpdatePayload,
  FormValues,
} from "./CompanyForm.helpers";

export default defineComponent({
  name: "CompanyForm",
  components: { Page, FormFooter, FormField, FormFieldCheckbox },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const companyId = computed<string>(() => route.params.companyId as string);

    const isCreation = computed<boolean>(() => companyId.value === "create");

    const [fetchCompany, { data: company, loading: isCompanyLoading }] =
      useResource<Nullable<FullCompanyType>>({
        fetchResource: () => getCompany(companyId.value),
        initialValue: null,
        resourceName: "Компания",
      });

    onMounted(() => {
      if (!isCreation.value) {
        fetchCompany();
      }
    });

    const values = ref<FormValues>(convertCompanyToFormValues(company.value));

    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);

    watch(company, () => {
      values.value = convertCompanyToFormValues(company.value);
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const creationBody = convertFormValuesToCompanyCreationPayload(
        values.value
      );

      const updateBody = convertFormValuesToCompanyUpdatePayload(values.value);
      const requestPromise = isCreation.value
        ? createCompany(creationBody)
        : updateCompany(companyId.value, updateBody);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getCompanyFormUrl({ companyId: response.data.id }));
          }
          if (event.type === "create_exit" || event.type === "save_exit") {
            router.push(getCompanyListUrl());
          }
          if (event.type === "create_create-another") {
            values.value = convertCompanyToFormValues(null);
          }

          toast.show({
            variant: "success",
            title: "Success",
            body: isCreation.value
              ? "Компания успешно добавлена"
              : "Компания успешно обновлена",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: isCreation.value
              ? "Ошибка при добавлении компании"
              : "Ошибка при обновлении компании",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(() => isCompanyLoading.value);

    const pageTitle = computed<string>(() => {
      return isCreation.value
        ? `Добавить компанию`
        : `Редактирование компании "${values.value.name}"`;
    });

    return {
      pageTitle,
      isContentLoading,
      values,
      errors,

      submitForm,
      backButtonUrl: getCompanyListUrl(),
      isSubmitting,
      isCreation,
    };
  },
});
