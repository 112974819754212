
import {computed, defineComponent} from 'vue';

import {MenuItemType, PageLayout} from '@tager/admin-layout';
import {getLinks} from '@/constants/links';
import {HomeIcon, SettingsIcon, ViewListIcon} from "@tager/admin-ui";

export default defineComponent({
  name: 'App',
  components: {PageLayout},
  setup() {
    const links = computed(() => getLinks());

    const sidebarMenuList: Array<MenuItemType> = [
      {id: 'home', icon: HomeIcon, ...links.value.HOME},
      {id: 'companies', icon: ViewListIcon, ...links.value.COMPANY_LIST},
      {id: 'payments', icon: ViewListIcon, ...links.value.PAYMENT_LIST},
      {id: 'settings', icon: ViewListIcon, ...links.value.SETTINGS_LIST},
      {
        id: 'admins',
        text: 'Администраторы',
        icon: SettingsIcon,
        children: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
      },
    ];

    return {
      sidebarMenuList,
    };
  },
});
