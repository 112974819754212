import { request, ResponseBody } from "@tager/admin-services";

import { CompanyType, FullCompanyType } from "./typings";

interface SuccessData {
  success: boolean;
}

interface CountData {
  count: number;
}

export function getCompanyCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/companies/count" });
}

export function getCompanyList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<CompanyType>>> {
  return request.get({ path: "/admin/companies", params });
}

export function getCompany(
  companyId: string | number
): Promise<ResponseBody<FullCompanyType>> {
  return request.get({ path: `/admin/companies/${companyId}` });
}

export function deleteCompany(
  companyId: string | number
): Promise<SuccessData> {
  return request.delete({ path: `/admin/companies/${companyId}` });
}

export interface CompanyCreationPayload {
  name: string;
  systemName: string;

  webpayActive: boolean;
  webpaySandbox: boolean;
  webpayStoreId: string;
  webpaySecretKey: string;
  webpayCabinetLogin: string;
  webpayCabinetPassword: string;

  webpayEripActive: boolean;
  webpayEripSandbox: boolean;
  webpayEripStoreId: string;
  webpayEripSecretKey: string;
  webpayEripCabinetLogin: string;
  webpayEripCabinetPassword: string;

  oplatiActive: boolean;
  oplatiSandbox: boolean;
  oplatiRegnum: string;
  oplatiPassword: string;

  assistActive: boolean;
  assistSandbox: boolean;
  assistMerchantId: string;
  assistUsername: string;
  assistPassword: string;
}

export function createCompany(
  payload: CompanyCreationPayload
): Promise<ResponseBody<FullCompanyType>> {
  return request.post({ path: "/admin/companies", body: payload });
}

export type CompanyUpdatePayload = CompanyCreationPayload;

export function updateCompany(
  companyId: number | string,
  payload: CompanyUpdatePayload
): Promise<ResponseBody<FullCompanyType>> {
  return request.put({
    path: `/admin/companies/${companyId}`,
    body: payload,
  });
}
