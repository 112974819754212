
import {
  computed,
  defineComponent,
  onMounted,
} from 'vue';
import {NavigationGridItem, NavigationGrid} from '@tager/admin-ui';
import {getLinks} from '@/constants/links';
import {useResource} from '@tager/admin-services';

import {getPaymentCount} from "@/modules/payments";
import {getCompanyCount} from "@/modules/companies";


export default defineComponent({
  name: 'Home',
  components: {
    NavigationGrid
  },
  setup() {
    const [
      fetchCompanyCount,
      {data: companyCountData, status: companyCountDataStatus},
    ] = useResource({
      fetchResource: getCompanyCount,
      resourceName: 'Количество компаний',
      initialValue: null,
    });

    const [
      fetchPaymentCount,
      {data: paymentCountData, status: paymentCountDataStatus},
    ] = useResource({
      fetchResource: getPaymentCount,
      resourceName: 'Количество платежей',
      initialValue: null,
    });

    onMounted(() => {
      fetchCompanyCount();
      fetchPaymentCount();
    });

    const links = computed(() => getLinks());

    const navItemList = computed<Array<NavigationGridItem>>(() => {
      return [
        {
          name: links.value.COMPANY_LIST.text,
          url: links.value.COMPANY_LIST.url,
          total: {
            status: companyCountDataStatus.value,
            value: companyCountData.value?.count ?? 0,
          },
          width: 50,
        },
        {
          name: links.value.PAYMENT_LIST.text,
          url: links.value.PAYMENT_LIST.url,
          total: {
            status: paymentCountDataStatus.value,
            value: paymentCountData.value?.count ?? 0,
          },
          width: 50,
        },
        {
          name: links.value.SETTINGS_LIST.text,
          url: links.value.SETTINGS_LIST.url,
          width: 50,
        },
        {
          name: 'Администраторы',
          linkList: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
          width: 50,
        },
      ];
    });

    return {
      navItemList,
    };
  },
});
