import { Nullable } from "@tager/admin-services";

import { FullCompanyType } from "../typings";
import { CompanyCreationPayload, CompanyUpdatePayload } from "../requests";

export interface FormValues {
  name: string;
  systemName: string;

  webpayActive: boolean;
  webpaySandbox: boolean;
  webpayStoreId: string;
  webpaySecretKey: string;
  webpayCabinetLogin: string;
  webpayCabinetPassword: string;

  webpayEripActive: boolean;
  webpayEripSandbox: boolean;
  webpayEripStoreId: string;
  webpayEripSecretKey: string;
  webpayEripCabinetLogin: string;
  webpayEripCabinetPassword: string;

  oplatiActive: boolean;
  oplatiSandbox: boolean;
  assistActive: boolean;
  assistSandbox: boolean;
  oplatiRegnum: string;
  oplatiPassword: string;
  assistMerchantId: string;
  assistUsername: string;
  assistPassword: string;
}

export function convertCompanyToFormValues(
  company: Nullable<FullCompanyType>
): FormValues {
  if (!company) {
    return {
      name: "",
      systemName: "",
      webpayActive: false,
      webpaySandbox: false,
      webpayStoreId: "",
      webpaySecretKey: "",
      webpayCabinetLogin: "",
      webpayCabinetPassword: "",

      webpayEripActive: false,
      webpayEripSandbox: false,
      webpayEripStoreId: "",
      webpayEripSecretKey: "",
      webpayEripCabinetLogin: "",
      webpayEripCabinetPassword: "",

      oplatiActive: false,
      oplatiSandbox: false,
      assistActive: false,
      assistSandbox: false,
      oplatiRegnum: "",
      oplatiPassword: "",
      assistMerchantId: "",
      assistUsername: "",
      assistPassword: "",
    };
  }

  return {
    name: company.name,
    systemName: company.systemName,

    webpayActive: company.webpayActive,
    webpaySandbox: company.webpaySandbox ?? false,
    webpayStoreId: company.webpayStoreId ?? "",
    webpaySecretKey: company.webpaySecretKey ?? "",
    webpayCabinetLogin: company.webpayCabinetLogin ?? "",
    webpayCabinetPassword: company.webpayCabinetPassword ?? "",

    webpayEripActive: company.webpayEripActive,
    webpayEripSandbox: company.webpayEripSandbox ?? false,
    webpayEripStoreId: company.webpayEripStoreId ?? "",
    webpayEripSecretKey: company.webpayEripSecretKey ?? "",
    webpayEripCabinetLogin: company.webpayEripCabinetLogin ?? "",
    webpayEripCabinetPassword: company.webpayEripCabinetPassword ?? "",

    oplatiActive: company.oplatiActive,
    oplatiSandbox: company.oplatiSandbox ?? false,
    assistActive: company.assistActive,
    assistSandbox: company.assistSandbox ?? false,
    oplatiRegnum: company.oplatiRegnum ?? "",
    oplatiPassword: company.oplatiPassword ?? "",
    assistMerchantId: company.assistMerchantId ?? "",
    assistUsername: company.assistUsername ?? "",
    assistPassword: company.assistPassword ?? "",
  };
}

export function convertFormValuesToCompanyCreationPayload(
  values: FormValues
): CompanyCreationPayload {
  return {
    name: values.name,
    systemName: values.systemName,

    webpayActive: values.webpayActive,
    webpaySandbox: values.webpaySandbox,
    webpayStoreId: values.webpayStoreId,
    webpaySecretKey: values.webpaySecretKey,
    webpayCabinetLogin: values.webpayCabinetLogin,
    webpayCabinetPassword: values.webpayCabinetPassword,

    webpayEripActive: values.webpayEripActive,
    webpayEripSandbox: values.webpayEripSandbox,
    webpayEripStoreId: values.webpayEripStoreId,
    webpayEripSecretKey: values.webpayEripSecretKey,
    webpayEripCabinetLogin: values.webpayEripCabinetLogin,
    webpayEripCabinetPassword: values.webpayEripCabinetPassword,

    oplatiActive: values.oplatiActive,
    oplatiSandbox: values.oplatiSandbox,
    oplatiRegnum: values.oplatiRegnum,
    oplatiPassword: values.oplatiPassword,

    assistActive: values.assistActive,
    assistSandbox: values.assistSandbox,
    assistMerchantId: values.assistMerchantId,
    assistUsername: values.assistUsername,
    assistPassword: values.assistPassword,
  };
}

export function convertFormValuesToCompanyUpdatePayload(
  values: FormValues
): CompanyUpdatePayload {
  return {
    ...convertFormValuesToCompanyCreationPayload(values),
  };
}
